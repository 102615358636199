<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      color="secondary"
      width=206
    >
      <v-list dense nav>
        <v-list-item
          link
          @click="limpiar_filtro"
          to="/admin/convocatorias"
        >
          <v-list-item-icon>
            <v-icon small>fas fa-bullhorn</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="ml-n5">Búsquedas activas</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          @click="limpiar_filtro"
          to="/admin/postulantes"
        >
          <v-list-item-icon>
            <v-icon small>fas fa-users</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="ml-n5">Postulantes</v-list-item-title>
        </v-list-item>

        <v-list-group
          no-action
          color="accent"
        >
          <v-icon slot="appendIcon" small>fas fa-chevron-down</v-icon>
          <template v-slot:activator>
            <v-icon slot="prependIcon" style="margin-left: 3px" small>fas fa-cog</v-icon>
            <v-list-item-content>
              <v-list-item-title class="ml-4">Configuración</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="([title, icon, to], i) in cruds"
            :key="i"
            link
            @click="limpiar_filtro"
            :to="to"
          >
            <v-list-item-title v-text="title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon small left v-text="icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      color="rojobb"
      flat
      dark
      dense
      app
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn
        v-if="!($vuetify.breakpoint.xs || $route.name === 'Curriculum Vitae')"
        class="mx-1"
        icon
        @click="vovler"
      ><v-icon>fas fa-arrow-left</v-icon></v-btn>
      <v-toolbar-title class="text-h6 text-sm-h5 font-weight-light">{{ currentRouteName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu
        offset-y
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
            :icon="$vuetify.breakpoint.xs"
          >
            <v-icon :left="!$vuetify.breakpoint.xs">fas fa-user-circle</v-icon>
            <div v-if="!$vuetify.breakpoint.xs">{{ user.nombre }}</div>
          </v-btn>
        </template>

        <v-list>
          <Password />
        </v-list>
      </v-menu>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-md-2"
            icon
            v-bind="attrs"
            v-on="on"
            @click="logout"
          >
            <v-icon>fas fa-sign-out-alt</v-icon>
          </v-btn>
        </template>
        <span>Cerrar sesión</span>
      </v-tooltip>

    </v-app-bar>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Password from '../Password'

export default {
  props: ['user'],
  data() {
    return {
      drawer: null,
      cruds: [
        ['Empresas', 'fas fa-building', '/admin/empresas'],
        ['Áreas', 'fas fa-network-wired', '/admin/areas'],
        ['Puestos', 'fas fa-briefcase', '/admin/puestos'],
        ['Etapas', 'fas fa-flag', '/admin/etapas'],
        ['Mensajes', 'fas fa-comment-alt', '/admin/mensajes'],
      ],
    }
  },
  components: {
    Password
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    }
  },
  methods: {  
    ...mapActions(['logout', 'limpiar_filtro']),
    vovler () {
      if (window.history !== null) this.$router.go(-1) 
    }
  },
}
</script>