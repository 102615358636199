export default {
  namespaced: true,
  state: {
    requisitos: [],
  },
  mutations:{
    set_requisitos(state, payload) {
      state.requisitos = payload
    },
    delete_requisito(state, payload) {
      state.requisitos.splice(payload, 1)
    },
    add_requisito(state, payload) {
      state.requisitos.push(payload)
    },
  },
  actions:{
    set_requisitos({ commit }, payload) {
      commit('set_requisitos', payload)
    },
    delete_requisito({ commit }, payload) {
      commit('delete_requisito', payload)
    },
    add_requisito({ commit }, payload) {
      commit('add_requisito', payload)
    },
  },
}