<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="info"
        small
        v-bind="attrs"
        v-on="on"
      >
        Ver todas
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 font-weight-light">Mis notificaciones</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="(item, index) in notificaciones"
            :key="index"
            class="py-1 px-0"
          >
            <NotificacionesCard :notificacion="item" :user="user" :nueva="!item.leida" @refresh="actualizar_popup" />
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="accent"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NotificacionesCard from './NotificacionesCard'

export default {
  data () {
    return {
      dialog: false,
    }
  },
  props: {
    notificaciones: Array,
    user: Object,
  },
  components: {
    NotificacionesCard
  },
  methods: {
    actualizar_popup () {
      this.$emit('refresh_popup')
    }
  }
}
</script>