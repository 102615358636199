<template>
  <v-dialog
    v-model="dialog"
    width="500"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-card
          :class="{ 'on-hover': hover }"
          :elevation="hover ? 3 : 0"
          style="cursor: pointer"
          width="100%"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-card-title class="body-1" style="word-break: normal">
            {{ notificacion.titulo }}
          </v-card-title>
          <v-card-subtitle v-if="nueva" class="success--text">¡Nueva!</v-card-subtitle>
        </v-card>
      </v-hover>
    </template>

    <v-card>
      <v-card-title class="text-h5 font-weight-light white--text rojobb mb-4" style="word-break: normal">
        {{ notificacion.titulo }}
      </v-card-title>

      <v-card-text class="body-1">
        <p>{{ notificacion.texto }}</p>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="primary"
          @click="dialog = false"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from '../firebase'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
    }
  },
  props: {
    notificacion: Object,
    user: Object,
    nueva: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    dialog (val) {
      val || this.marcar_como_leida()
    }
  },
  methods: {
    ...mapActions(['update_user']),
    async marcar_como_leida () {
      if (!this.notificacion.leida) {
        let notificaciones = [...this.user.notificaciones]
        const index = notificaciones.indexOf(this.notificacion)
        notificaciones[index].leida = true

        await db.collection('usuarios').doc(this.user.id).update({ notificaciones: notificaciones })
          .then(() => {
            this.user.notificaciones = notificaciones
            this.update_user(this.user)
            this.$emit('refresh')
          })
          .catch((error) => {
            console.log(error)
          }) 
      }
    },
  }
}
</script>