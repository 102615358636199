<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="400"
    >
      <v-card>
        <v-card-title class="text-h5 font-weight-light">Iniciar sesión</v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="login()">
            <v-text-field
              v-model.trim="email"
              :rules="[rules.required, rules.email]"
              label="E-mail"
              validate-on-blur
            ></v-text-field>

            <v-text-field
              v-model.trim="password"
              :rules="[rules.required, rules.min]"
              :append-icon="show ? 'fas fa-eye' : 'fas fa-eye-slash'"
              :type="show ? 'text' : 'password'"
              label="Contraseña"
              @click:append="show = !show"
              validate-on-blur
            ></v-text-field>

            <Recuperacion :email="email"/>

            <v-alert
              v-if="error"
              border="left"
              dense
              text
              type="error"
              class="mt-2 mb-n1"
            >{{ error_message }}</v-alert>
      
            <v-row class="mt-2">
              <v-col>
                <v-btn
                  color="success"
                  block
                  type="submit"
                  :loading="load"
                >
                  Iniciar sesión
                </v-btn>
              </v-col>
              <v-col>
                <template>
                  <v-btn
                    color="info"
                    block
                    :disabled="load"
                    @click.stop="registro"
                  >
                    Registrarse
                  </v-btn>
                  <Singup v-model="show_singup" />
                </template>
              </v-col>
              <v-col cols="12" class="d-flex align-center my-n3">
                <v-divider></v-divider>
                <div class="mx-3">ó</div>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  :block="$vuetify.breakpoint.xs"
                  class="px-md-6 px-0"
                  @click="with_google"
                >
                  <div>Acceder con</div>
                  <v-icon right color="#4285F4" class="mb-1">fab fa-google</v-icon>
                  <strong class="d-flex ml-1 mb-1 text-h6 text-lowercase">
                    <div style="color: #DB4437">o</div>
                    <div style="color: #F4B400">o</div>
                    <div style="color: #4285F4">g</div>
                    <div style="color: #0F9D58">l</div>
                    <div style="color: #DB4437">e</div>
                  </strong>
                </v-btn>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth } from '../firebase'
import { mapActions } from 'vuex'
import firebase from 'firebase/app'
import Recuperacion from '../components/Recuperacion'
import Singup from '../components/Singup'

export default {
  data() {
    return {
      show: false,
      show_singup: false,
      load: false,
      error: false,
      error_message: '',
      email: '',
      password: '',
      rules: {
        required: value => !!value || 'Campo requerido',
        min: value => value.length >= 6 || 'Mínimo 6 caracteres',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'E-mail inválido'
        },
      },
    }
  },
  props: {
    value: Boolean
  },
  components: {
    Recuperacion, Singup
  },
  watch: {
    dialog (val) {
      val || this.clear()
    },
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
  methods: {
    ...mapActions(['login_signup']),
    async login() {
      if (this.$refs.form.validate()) {

        this.error = false
        this.load = true

        await auth.signInWithEmailAndPassword(this.email, this.password)
          .then((userCredential) => {
            this.dialog = false
            this.login_signup(userCredential.user)
          })
          .catch((error) => {
            this.error = true
            if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
              this.error_message = 'E-mail o contraseña incorrectos'
            } else if (error.code === 'auth/too-many-requests') {
              this.error_message = 'Ha enviado demasiadas solicitudes de ingreso fallidas para el mismo e-mail. Vuelva a intentarlo más tarde'
            } else {
              this.error_message = error.message
            }
          })

        this.load = false

      }
    },
    async with_google() {
      const provider = new firebase.auth.GoogleAuthProvider()
      auth.languageCode = 'es'
/*
      if (this.$vuetify.breakpoint.xs) {
        await auth.signInWithRedirect(provider)
        await auth.getRedirectResult()
          .then((result) => {
            this.dialog = false
            this.login_signup(result.user)
          }).catch((error) => {
            console.log(error)
          })
      } else {*/
        await auth.signInWithPopup(provider)
          .then((result) => {
            this.dialog = false
            this.login_signup(result.user)
          }).catch((error) => {
            console.log(error)
          })
      //}
    },
    clear() {
      this.error = false
      this.show = false
      this.email = ''
      this.password = ''
      this.$refs.form.resetValidation()
    },
    registro() {
      this.show_singup = true
      this.dialog = false
    }
  },
}
</script>