import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyC9YstYue2pnZIX63ZSM4lfW1ZsbqvvWjM",
  authDomain: "bbjobs-70064.firebaseapp.com",
  projectId: "bbjobs-70064",
  storageBucket: "bbjobs-70064.appspot.com",
  messagingSenderId: "764373235561",
  appId: "1:764373235561:web:ad6ca8c67cb9dd6e09eff8",
  measurementId: "G-EJXSJHK5P1"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

export { db, auth, storage }