import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#3F51B5',
        secondary: '#EEEEEE',
        accent: '#333333',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        rojobb: '#CC0001',
        amarillo: '#FFF9C4',
        azul: '#B3E5FC',
        celeste: '#E3F2FD',
        rojo: '#FFCDD2',
        verde: '#C5E1A5',
      },
    },
  },
    lang: {
      locales: { es },
      current: 'es',
    },
  icons: {
    iconfont: 'fa',
  },
})
