<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="450"
      persistent
    >
      <v-card>
        <v-card-title>
          Aceptar términos y condiciones
        </v-card-title>
        <v-card-text class="body-1">
          Para continuar utilizando nuestra plataforma debe aceptar los nuevos
          <v-hover v-slot="{ hover }">
            <span
              style="cursor: pointer"
              class="primary--text"
              :class="hover ? 'text-decoration-underline' : ''"
              @click="dialog_tyc = true"
            >
              términos y condiciones
            </span>
          </v-hover>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            :disabled="load"
            @click="rechazar()"
          >
            Rechazar
            <v-icon right>fas fa-times</v-icon>
          </v-btn>
          <v-btn
            color="success"
            class="ml-4"
            :loading="load"
            @click="aceptar()"
          >
            Aceptar
            <v-icon right>fas fa-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TerminosYCondiciones v-model="dialog_tyc" />
  </div>
</template>

<script>
import { db } from '../firebase'
import TerminosYCondiciones from './TerminosYCondiciones'

export default {
  data () {
    return {
      dialog_tyc: false,
      load: false
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    TerminosYCondiciones
  },
  methods: {
    async aceptar () {
      this.load = true
      try {
        // obtiene la ultima version de los tyc
        let version = await this.$store.dispatch('get_last_version_tyc')
        // actualiza el usuario
        await db.collection('usuarios').doc(this.$store.state.user.id).update({
          terminos_condiciones: {
            acepto: true,
            version: version
          }
        })
          .then (() => {
            this.$store.state.dialog_tyc = false
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      } catch (error) {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
      this.load = false
    },
    rechazar () {
      this.$store.dispatch('logout')
      this.$store.state.dialog_tyc = false
    }
  }
}
</script>