<template>
  <v-app>
    
    <div v-if="verify_token()">
      <AdminBar v-if="user.rol === 'Admin'" :user="user" />
      <PostulanteBar v-else :user="user" />
    </div>

    <v-app-bar
      v-else
      color="rojobb"
      dark app flat dense
    >
      <v-toolbar-title class="text-h5" style="cursor: default">BB Jobs</v-toolbar-title>
      <v-spacer></v-spacer>
      <template>
        <v-btn text @click.stop="show_login = true">Ingresar</v-btn>
        <Login v-model="show_login" />
      </template>
      <template v-if="!$vuetify.breakpoint.xs">
        <v-btn text @click.stop="show_singup = true">Registrarse</v-btn>
        <Singup v-model="show_singup" />
      </template>
    </v-app-bar>

    <v-main :style="color">
      <v-container :style="$vuetify.breakpoint.xl ? '' : 'max-width:100%'">
        <div v-if="loading" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mt-5"
          ></v-progress-circular>
        </div>

        <router-view v-else/>

        <div
          @mouseover="reset_progress()"
          @mouseleave="start_progress()"
        >
          <v-snackbar
            v-model="$store.state.snackbar.value"
            class="mb-4"
            elevation="12"
            transition="slide-y-reverse-transition"
            :timeout="$store.state.snackbar.timeout"
            :color="$store.state.snackbar.color"
          >
            <!-- <v-icon
              v-if="$store.state.snackbar.color == 'error' || $store.state.snackbar.color == 'success' || $store.state.snackbar.color == 'warning'"
              v-text="$store.state.snackbar.color == 'error' ? 'fas fa-exclamation-circle' : ($store.state.snackbar.color == 'success' ? 'fas fa-check-circle' : 'fas fa-exclamation-triangle')"
              left
            ></v-icon> -->
            <span
              v-html="$store.state.snackbar.text"
              class="body-1 mb-4"
            ></span>
            <v-progress-linear
              v-model="value"
              color="white"
              class="mb-n1 mt-3"
              style="margin-left: 22px"
            ></v-progress-linear>
            <template v-slot:action="{ attrs }">
              <v-btn
                v-bind="attrs"
                class="mb-3"
                title="Cerrar"
                icon
                @click="$store.state.snackbar.value = false"
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-container>
    </v-main>

    <v-footer
      v-if="!(verify_token() && user.rol === 'Admin')"
      dark
      padless
    >
      <v-card
        class="flex"
        flat
        tile
      >
        <v-card-text class="py-1 white--text text-center font-weight-thin" style="font-size: 12px">
          <div v-if="$vuetify.breakpoint.smAndDown">
            <div>Borigen Betzel Argentina</div>
            <div>Copyright © {{ new Date().getFullYear() }} Todos los derechos reservados</div>
          </div>
          <div v-else>
            Copyright © {{ new Date().getFullYear() }} Todos los derechos reservados | Borigen Betzel Argentina
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
    
    <LoadingScreen />
    <AceptarTYC v-model="$store.state.dialog_tyc" />
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { VueEasyJwt } from "vue-easy-jwt"
import AdminBar from './components/appbars/AdminBar'
import PostulanteBar from './components/appbars/PostulanteBar'
import Login from './components/Login'
import Singup from './components/Singup'
import LoadingScreen from './components/LoadingScreen'
import AceptarTYC from './components/AceptarTYC'

const jwt = new VueEasyJwt()

export default {
  data () {
    return {
      value: 100,
      interval: 0,
      show_login: false,
      show_singup: false,
    }
  },
  async created() {
    await this.loadLocalStorage()
  },
  components: {
    AdminBar, PostulanteBar, Login, Singup, LoadingScreen, AceptarTYC
  },
  computed: {
    ...mapState(['user', 'loading']),
    color(){
      return [ this.user.rol === 'Admin' ? this.$route.name === 'Home' ? { 'background-color' : '#f0f0f0' } : { } : { 'background-color' : '#f0f0f0' } ]
    },
  },
  watch: {
    // cuenta regresiva del snackbar
    '$store.state.snackbar.value' (val) {
      if (val) {
        this.start_progress()
      }
    }
  },
  methods: {
    ...mapActions(['loadLocalStorage']),
    verify_token () {
      return !jwt.isExpired(this.$store.state.user.token)
    },
    reset_progress () {
      clearInterval(this.interval)
      this.value = 100
    },
    start_progress () {
      this.reset_progress()
      this.interval = setInterval(() => {
        this.value -= 10000 / this.$store.state.snackbar.timeout
      }, 100)
    }
  },
}
</script>

<style>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tbody>tr>th, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>th, .v-data-table>.v-data-table__wrapper>table>thead>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  padding: 0 8px !important;
}
.cebra tr:nth-of-type(even) { /* con even empieza el priero con color mas claro y con odd empieza el segundo */
  background-color: rgba(0, 0, 0, .1);
}
</style>