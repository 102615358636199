import Vue from 'vue'
import Vuex from 'vuex'
import { db, auth } from '../firebase'
import router from '../router'
import { VueEasyJwt } from "vue-easy-jwt"

import funciones from '../modules/funciones'
import requisitos from '../modules/requisitos'
import links from '../modules/links'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    default_tyc_version: 1,
    dialog_tyc: false,
    loading_screen: false,
    snackbar: {
      value: false,
      text: '',
      color: '',
      timeout: 3000
    },
    badge: {
      total: 0,
      visto: 0
    },
    user: '',
    estado: '',
    loading: false,
    empresas: [],
    empresas_select: [],
    convocatorias: [],
    provincias: [],
    tipos_documentos: [],
    fecha: {
      desde: null,
      hasta: null,
    },
    filtro: {
      cuit: '',
      nombre: '',
      apellido: '',
      abreviacion: '',
      empresa: '',
      area: '',
      inhabilitado: false,
      provincia: '',
      departamento: '',
      localidad: '',
      etapa: '',
      carnet: false,
      insc_mas_1_conv: false,
      sin_post: false,
      visto: 'No visto',
      rechazado: 'Todos',
      fecha: {
        desde: null,
        hasta: null,
      },
      telefono: null
    },
    filtro_conv: {
      busqueda: '',
      empresa: '',
      area: '',
      puesto: '',
      fecha: {
        inicio: {
          desde: null,
          hasta: null,
        },
        fin: {
          desde: null,
          hasta: null,
        },
      },
      activa: true,
    },
  },

  mutations: {
    set_user(state, payload) {
      state.user = payload
      localStorage.setItem('user', JSON.stringify(payload))
    },
    set_snackbar(state, payload) {
      state.snackbar = payload
    },
    set_estado(state, payload) {
      state.estado = payload
    },
    set_loading(state, payload) {
      state.loading = payload
    },
    set_empresas(state, payload) {
      state.empresas = payload
    },
    set_empresas_select(state, payload) {
      state.empresas_select = payload
    },
    set_convocatorias(state, payload) {
      state.convocatorias = payload
    },
    delete_convocatoria(state, payload) {
      state.convocatorias.splice(payload, 1)
    },
    set_provincias(state, payload) {
      state.provincias = payload
      localStorage.setItem('provincias', JSON.stringify(payload))
    },
    set_tipos_documentos(state, payload) {
      state.tipos_documentos = payload
      localStorage.setItem('tipos_documentos', JSON.stringify(payload))
    },
    set_fecha_desde(state, payload) {
      if (payload.filtro) {
        if (payload.inicio) {
          state.filtro_conv.fecha.inicio.desde = payload.fecha
        } else {
          state.filtro_conv.fecha.fin.desde = payload.fecha
        }
      } else {
        state.fecha.desde = payload.fecha
      }
    },
    set_fecha_hasta(state, payload) {
      if (payload.filtro) {
        if (payload.inicio) {
          state.filtro_conv.fecha.inicio.hasta = payload.fecha
        } else {
          state.filtro_conv.fecha.fin.hasta = payload.fecha
        }
      } else {
        state.fecha.hasta = payload.fecha
      }
    },
    set_filtro(state, payload) {
      state.filtro = payload
    },
    set_filtro_conv(state, payload) {
      state.filtro_conv = payload
      localStorage.setItem('filtro_conv', JSON.stringify(payload))
    },
    load_filtro_conv(state, payload) {
      state.filtro_conv = payload
    },
    load_user(state, payload) {
      state.user = payload
    },
    load_tipos_documentos(state, payload) {
      state.tipos_documentos = payload
    },
    load_provincias(state, payload) {
      state.provincias = payload
    },
  },

  actions: {

    // abre el modal de tyc si no los tiene aceptados
    async abrir_modal_tyc ({ state, dispatch }, user_id) {
      state.loading_screen = true
      let acepto = await dispatch('verificar_acepto_tyc', user_id)
      if (acepto != 1) {
        state.dialog_tyc = true
      }
      state.loading_screen = false
    },

    // devuelve un 0 o 1 si acepto o no la version mas reciente de los tyc
    async verificar_acepto_tyc ({ dispatch }, user_id) {
      return await new Promise(async (resolve, reject) => {
        try {
          await db.collection('usuarios').doc(user_id).get()
            .then(async (doc) => {
              if (doc.exists) {
                const data = doc.data().terminos_condiciones
                // obtiene la ultima version de los tyc
                let version = await dispatch('get_last_version_tyc')
                // verifica que tenga aceptada la ultima version de los tyc
                if (data && (data.acepto && data.version == version)) {
                  resolve(1)
                } else {
                  resolve(0)
                }
              } else {
                resolve(0)
              }
            })
            .catch((error) => {
              resolve(0)
            })
        } catch (error) {
          resolve(0)
        }
      })
    },

    async get_last_version_tyc ({ state }) {
      return await new Promise(async (resolve, reject) => {
        try {
          await db.collection('configuracion').doc('terminos_condiciones').get()
            .then((doc) => {
              if (doc.exists) {
                resolve(doc.data().version)
              } else {
                resolve(state.default_tyc_version)
              }
            })
            .catch((error) => {
              resolve(state.default_tyc_version)
            })
        } catch (error) {
          resolve(state.default_tyc_version)
        }
      })
      
    },

    show_snackbar ({ commit }, payload) {
      let snackbar = {
        value: true,
        text: payload.text,
        color: payload.color,
        timeout: 3000
      }
      if (payload.timeout) {
        snackbar.timeout = payload.timeout
      }
      commit('set_snackbar', snackbar)
    },

    async login_signup({ commit, dispatch, state }, payload) {

      await db.collection('usuarios').doc(payload.uid).get()
        .then(async (doc) => {
          if (doc.exists) {

            state.loading = true

            let user = doc.data()
            user.id = payload.uid
            await auth.currentUser.getIdToken(true)
              .then((idToken) => {
                const jwt = new VueEasyJwt()
                user.token = idToken
                user.email = auth.currentUser.email
              }).catch((error) => {
                console.log(error)
              })
            commit('set_user', user)

            if (state.user.rol === 'Admin') {
              await dispatch('get_empresas')
              await dispatch('set_convocatorias', [])
              if (router.history.pending === null) {
                await router.push('/admin/convocatorias').catch(()=>{})
              } else {
                await router.push(router.history.pending.path).catch(()=>{})
              }
            } else {
              if (state.user.cv.completo) {
                if (router.history.pending === null) {
                  await router.push('/').catch(()=>{})
                } else {
                  await router.push(router.history.pending.path).catch(()=>{})
                }
              } else {
                await router.push('/cv').catch(()=>{})
              }
              await dispatch('abrir_modal_tyc', user.id)
            }

            state.loading = false

          } else {
            await dispatch('set_user_google', payload)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },

    logout({ commit, state }) {
      auth.signOut().then(async () => {
        commit('set_user', '')
        if (router.currentRoute.path !== '/') {
          commit('set_convocatorias', [])
          await router.push('/').catch(()=>{})
        } else {
          commit('set_convocatorias', state.convocatorias.filter(conv => !conv.pasantia.value))
        }
      }).catch((error) => {
        console.log(error)
      })
    },

    async get_empresas({ commit }) {
      let empresas = []
      let empresas_select = []
      await db.collection('empresas').where('inhabilitado', '==', false).get()
        .then((res) => {
          res.forEach((doc) => {
            let empresa = doc.data()
            empresa.id = doc.id
            empresas.push(empresa)

            empresas_select.push({
              text: empresa.abreviacion,
              value: empresa.id,
            })
          })

          commit('set_empresas', empresas)
          commit('set_empresas_select', empresas_select)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async get_provincias({ commit }) {
      let provincias = []
      await db.collection('provincias').orderBy('nombre').get()
        .then((res) => {
          res.forEach((doc) => {
            provincias.push(doc.data())
          })
          commit('set_provincias', provincias)
          localStorage.setItem('provincias', JSON.stringify(provincias))
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async get_tipos_documentos({ commit }) {
      let tipos_documentos = []
      await db.collection('tipos_documentos').get()
        .then(res => {
          res.forEach(doc => {
            tipos_documentos.push(doc.data().tipo)
          })
          commit('set_tipos_documentos', tipos_documentos)
          localStorage.setItem('tipos_documentos', JSON.stringify(tipos_documentos))
        })
        .catch((error) => {
          console.log(error)
        })
    },

    set_estado({ commit }, payload) {
      commit('set_estado', payload)
    },
    set_convocatorias({ commit }, payload) {
      commit('set_convocatorias', payload)
    },
    delete_convocatoria({ commit }, payload) {
      commit('delete_convocatoria', payload)
    },

    set_fecha_desde({ commit }, payload) {
      commit('set_fecha_desde', payload)
    },
    set_fecha_hasta({ commit }, payload) {
      commit('set_fecha_hasta', payload)
    },

    limpiar_filtro({ commit }) {
      commit('set_filtro', {
        cuit: '',
        nombre: '',
        apellido: '',
        abreviacion: '',
        empresa: '',
        area: '',
        inhabilitado: false,
        provincia: '',
        departamento: '',
        localidad: '',
        etapa: '',
        carnet: false,
        insc_mas_1_conv: false,
        sin_post: false,
        visto: 'No visto',
        rechazado: 'Todos',
        fecha: {
          desde: null,
          hasta: null,
        },
      })
    },

    set_filtro_conv({ commit }, payload) {
      commit('set_filtro_conv', payload)
    },

    update_user({ commit }, payload) {
      commit('set_user', payload)
    },

    async loadLocalStorage({ commit, dispatch, state }) {
      // filtro de convocatorias
      if (localStorage.getItem('filtro_conv')) {
        commit('load_filtro_conv', JSON.parse(localStorage.getItem('filtro_conv')))
      }
      else {
        localStorage.setItem('filtro_conv', JSON.stringify(state.filtro_conv))
      }

      // usuario
      if (localStorage.getItem('user')) {
        commit('load_user', JSON.parse(localStorage.getItem('user')))
      }
      else {
        localStorage.setItem('user', JSON.stringify(state.user))
      }

      // tipo de documentos
      if (localStorage.getItem('tipos_documentos')) {
        commit('load_tipos_documentos', JSON.parse(localStorage.getItem('tipos_documentos')))
      }
      else {
        await dispatch('get_tipos_documentos')
      }

      // provincias
      if (localStorage.getItem('provincias')) {
        commit('load_provincias', JSON.parse(localStorage.getItem('provincias')))
      }
      else {
        await dispatch('get_provincias')
      }
    },

    async set_user_google({ commit, state }, payload) {
      state.loading = true

      let bienvenida = { titulo: 'N/A', texto: 'N/A' }
      await db.collection('mensajes_predefinidos').doc('bienvenida').get()
        .then((doc) => {
          if (doc.exists) {
            bienvenida.titulo = doc.data().titulo
            bienvenida.texto = doc.data().texto
          }
        })
        .catch((error) => {
          console.log(error)
        })

      let google = { titulo: 'N/A', texto: 'N/A' }
      await db.collection('mensajes_predefinidos').doc('sesion_google').get()
        .then((doc) => {
          if (doc.exists) {
            google.titulo = doc.data().titulo
            google.texto = doc.data().texto
          }
        })
        .catch((error) => {
          console.log(error)
        })

      let nombre = payload.displayName
      if (nombre) {
        nombre = nombre.toUpperCase()
      }

      let usuario = {
        rol: 'Postulante',
        apellido: '',
        nombre: nombre,
        foto: payload.photoURL,
        tipo_cuenta: 'Google',
        tipo_documento: '',
        documento: '',
        notificaciones: [
          {
            leida: false,
            titulo: bienvenida.titulo,
            texto: bienvenida.texto
          },
          {
            leida: false,
            titulo: google.titulo,
            texto: google.texto
          },
        ],
        postulaciones: [],
        archivos: [],
        enlaces: [],
        cv: {
          completo: false,
          log: { creacion: new Date(Date.now()).toISOString().substr(0, 10), modificacion: new Date(Date.now()).toISOString().substr(0, 10) },
          cuil: '',
          provincia: '',
          departamento: '',
          localidad: '',
          domicilio: '',
          nacimiento: { fecha: '', lugar: '' },
          telefono: { celular: '', alternativo: ''},
          formacion: [],
          conocimientos: [],
          experiencia: [],
          disp_horaria: '',
          pretension_salarial: '',
          carnet_conducir: false,
        }
      }

      await db.collection('usuarios').doc(payload.uid).set(usuario)
        .then(async () => {
          usuario.id = payload.uid
          await auth.currentUser.getIdToken(true)
            .then((idToken) => {
              const jwt = new VueEasyJwt()
              usuario.token = idToken
              usuario.email = auth.currentUser.email
            }).catch((error) => {
              console.log(error)
            })
          commit('set_user', usuario)
          await router.push('/cv').catch(()=>{})
          await dispatch('abrir_modal_tyc', usuario.id)
        })
        .catch((error) => {
          console.log(error)
        })
        state.loading = false
    }

  },

  modules: {
    funciones, requisitos, links
  },

  getters: {
    exist_user(state) {
      return state.user !== ''
    },
    postulaciones(state) {
      return state.user.postulaciones
    },
    rol(state) {
      return state.user.rol
    }
  }
})
