<template>
  <v-dialog
    v-model="dialog"
    max-width="650"
    scrollable
  >
    <v-card>
      <v-card-title class="text-h5" style="word-break: normal">
        POLÍTICA DE PRIVACIDAD
      </v-card-title>
      <v-card-text class="scrolltotop">
        <h3>
          1. NOS COMROMETEMOS CON LA PRIVACIDAD Y SEGURIDAD DEL USUARIO
        </h3>
        <p>
          Las empresas que conforman al Grupo Borigen Betzel, adhieren a esta política de privacidad, demostrando el respeto por la privacidad de toda persona que visite y se registre en nuestro Sitio Web BB.
        </p>
        <h3>
          2. OBTENCIÓN Y USO DE SU INFORMACIÓN
        </h3>
        <p>
          <strong>2.1.</strong> Esta Política de Privacidad contempla la obtención y uso de información personal en el la Web BB (de esta forma se referirá a la
          página <a href="https://www.borigenbetzel.com.ar" target="_blank">https://www.borigenbetzel.com.ar</a>).
        </p>
        <p>
          <strong>2.2.</strong> La Web BB no obtendrá a través del él ninguna información sobre usted que pueda identificarlo personalmente, como, por ejemplo, su nombre, dirección, número de teléfono o dirección
          de correo electrónico (en adelante, la " Datos Personales "), a menos que usted nos la brinde en forma voluntaria.
        </p>
        <p>
          Si el usuario no desea que obtengamos su Información Personal, por favor evite proporcionarla. Sin embargo, deberá tener en cuenta que en caso de no proporcionar su Información Personal, no podrá
          acceder a las ofertas de empleo disponibles a través del la Web BB.
        </p>
        <p>
          <strong>2.3.</strong> En caso de que usted nos comparta su Información Personal, le comunicamos que esa Información Personal será incorporada a la base de datos de la Web BB.
        </p>
        <p>
          <strong>2.4.</strong> La Web BB en ningún momento recopilará información sensible sobre usted.
        </p>
        <h3>
          3. DATOS PERSONALES QUE RECOPILAMOS
        </h3>
        <p>
          <strong>3.1.</strong> Cuando usted, está interesado en hacer uso del servicio “Trabajá con nosotros” de la WEB BB, deberá crear una cuenta personal o registrarse con su cuenta personal existente
          que le permita posicionarse como Candidato a las ofertas laborales ofrecidas, como la recepción de alertas o simplemente en el caso de registrarse en la plataforma, puede ser necesario que nos proporcione
          los siguientes datos: nombre y apellidos, dirección de correo electrónico, así como, en su caso, una contraseña.
        </p>
        <p>
          También, podrás incluir en tu perfil tus datos académicos, experiencia laboral y aptitudes, preferencias, intereses, una fotografía, tu ciudad o ubicación. También podrás subir tu curriculum vitae en formato
          Word o Pdf, tu video presentación entre otros. 
        </p>
        <p>
          <strong>3.2.</strong> Asimismo, recibimos datos de tus dispositivos y redes, incluidos los datos de ubicación, información sobre tu dirección IP, servidor proxy, sistema operativo, navegador web y complementos.
        </p>
        <p>
          <strong>3.3.</strong> En los casos que nos brinde sus Datos Personales, usted acepta y presta su consentimiento libre, expreso e informado para que dichos Datos Personales sean utilizados con la finalidad
          arriba mencionada y autorizada a que los mismos sean tratados, almacenados, recopilados en las bases de datos de propiedad de WEB BB. En tal sentido, usted autoriza a que sus Datos Personales sean compartidos
          con las empresas que conforman al Grupo Borigen Betzel. Asimismo, usted acepta y presta su consentimiento libre, expreso e informado con los términos de la presente Política de Privacidad.
        </p>
        <h3>
          4. FINALIDADES Y LEGITIMACIÓN DEL PORTAL “TRABAJÁ CON NOSOTROS”
        </h3>
        <p>
          El objetivo del Sitio WEB BB mediante los servicios ofrecidos en la plataforma “Trabaja con nosotros” es el de facilitar el contacto entre los postulantes y las empresas del Grupo Borigen Betzel que ofrecen
          puestos de trabajo en las diferentes unidades de negocio.
        </p>
        <p>
          En concreto, los Datos Personales que nos suministres al registrarte y/o utilizar nuestro portal web serán destinados para las siguientes finalidades:
        </p>
        <p>
          <strong>4.1.</strong> Permitir la búsqueda y/o selección de tu perfil.
        </p>
        <p>
          <strong>4.2.</strong> Lectura y gestión de la información en tu currículum vitae.
        </p>
        <p>
          <strong>4.3.</strong> Gestión de nuestros servicios, en el caso concreto de que utilices nuestros datos de contacto para manifestarnos consultas o reclamaciones que nos plantees a través de los medios
          de comunicación propuestos (Dirección física Av. Belgrano 839, A4400 Salta y teléfono +543874312030).
        </p>
        <p>
          <strong>4.4.</strong> Utilizar tus datos para mejorar la aplicación práctica y funcional de nuestra web y optimizar nuestros servicios y propuestas.
        </p>
        <h3>
          5. TIEMPO DE PRESERVACIÓN DE LOS DATOS PERSONALES
        </h3>
        <p>
          Los Datos Personales que nos facilites serán guardados mientras tu cuenta en nuestro portal web siga activa o en la medida en que sea necesario para proporcionarte nuestros servicios, hasta que solicites
          su supresión, así como durante el tiempo necesario para cumplir las obligaciones legales.
        </p>
        <h3>
          6. PROTEGIENDO SUS DATOS PERSONALES
        </h3>
        <p>
          <strong>6.1.</strong> Para prevenir el acceso no autorizado y asegurar tus Datos Personales, la WEB BB, utiliza estrictos procedimientos electrónicos de seguridad para resguardarlos. 
        </p>
        <p>
          Asi mismo, el personal que brinda tratamiento a tu información personal ha sido debidamente capacitado para tratar dichos datos de forma confidencial, privada y protegida.
        </p>
        <h3>
          7. DERECHOS DEL USUARIO
        </h3>
        <p>
          Como titular de tus Datos Personales registrados, tienes los siguientes derechos:
        </p>
        <p>
          <strong>• Acceder, cambiar o rectificar tus Datos Personales:</strong> puedes editar parte de tus Datos Personales. Asimismo, podrás solicitarnos la modificación, actualización o corrección de tus
          Datos si éstos no son precisos.
        </p>
        <p>
          <strong>• Rechazar, limitar, oponerte o restringir el uso de Datos:</strong> tienes derecho a solicitarnos que dejemos de usar la totalidad o parte de tus Datos Personales, salvo por motivos legítimos
          imperiosos o el ejercicio o defensa de posibles reclamaciones.
        </p>
        <p>
          <strong>• Suprimir tus Datos:</strong> puedes requerirnos borrar o eliminar todos o parte de tus Datos Personales.
        </p>
        <p>
          Si deseas hacer uso de cualquiera de tus derechos puedes dirigirte a nosotros utilizando la información de contacto que aparece al inicio de la presente política y en el apartado 10; gestionaremos
          tu solicitud conforme a la legislación aplicable.
        </p>
        <h3>
          8. EDAD MÍNIMA DEL USUARIO
        </h3>
        <p>
          Para acceder a los servicios de nuestra Web BB debes tener 18 años de edad o más. 
        </p>
        <p>
          Si tenemos conocimiento de que un menor de edad nos ha proporcionado información sin autorización parental, eliminaremos dicha información y suprimiremos la cuenta de referido menor.
        </p>
        <h3>
          9. CAMBIOS O ACTUALIZACIONES A LA POLÍTICA DE PRIVACIDAD
        </h3>
        <p>
          WEB BB se reserva el derecho a modificar esta Política de Privacidad cuando fuese necesario, es obligación de los usuarios revisar regularmente esta sección a fin de informarse de cualquier modificación
          que se pueda haber producido. De todos modos, BB cursará una notificación a efectos de informarle sobre el cambio en la Política de Privacidad.
        </p>
        <p>
          Si no estás de acuerdo con cualquiera de los cambios, gozas del pleno derecho a cerrar tu cuenta de usuario del portal web.
        </p>
        <h3>
          10. CONTACTO
        </h3>
        <p>
          Si tiene preguntas sobre su privacidad cuando utilices la Web BB, por favor contáctenos al siguiente número telefónico: +543875043465.
        </p>
        <p>
          Esta Política de Privacidad fue actualizada por última vez el 17 de marzo de 2022.
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="accent"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      val || (document.getElementsByClassName('scrolltotop')[0].scrollTop = 0)
    }
  }
}
</script>

<style>
h3 {
  padding-bottom: 8px;
}
p {
  text-align: justify;
  text-justify: inter-word;
}
</style>