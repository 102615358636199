<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="400"
      scrollable
      :persistent="load"
    >
      <v-card>
        <v-card-title class="text-h5 font-weight-light">
          <div>Crear una cuenta</div>
          <div v-if="!$vuetify.breakpoint.xs" class="text-h6 font-weight-light mb-n1">
            ( o acceder con
            <v-btn
              :block="$vuetify.breakpoint.xs"
              class="pr-1 pl-0"
              text
              small
              @click="login_google"
            >
              <v-icon right color="#4285F4" class="mb-1">fab fa-google</v-icon>
              <strong class="d-flex ml-1 mb-1 text-h6 text-lowercase">
                <div style="color: #DB4437">o</div>
                <div style="color: #F4B400">o</div>
                <div style="color: #4285F4">g</div>
                <div style="color: #0F9D58">l</div>
                <div style="color: #DB4437">e</div>
              </strong>
            </v-btn>
            )
          </div>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-form ref="form" @submit.prevent="singup()">
            <v-text-field
              v-model.trim="apellido"
              :rules="[rules.required]"
              label="Apellido/s"
              validate-on-blur
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model.trim="nombre"
              :rules="[rules.required]"
              label="Nombre/s"
              validate-on-blur
              outlined
              dense
            ></v-text-field>

            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" sm="6">
                <v-select
                  v-model="tipo_documento"
                  :items="tipos_documentos"
                  label="Tipo de documento"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="documento"
                  :rules="[rules.required]"
                  label="Nº documento"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              v-model.trim="email"
              :rules="[rules.required, rules.email]"
              label="E-mail"
              validate-on-blur
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model.trim="password"
              :rules="[rules.required, rules.min]"
              :append-icon="show ? 'fas fa-eye' : 'fas fa-eye-slash'"
              :type="show ? 'text' : 'password'"
              label="Contraseña"
              hint="Mínimo 6 caracteres"
              counter
              @click:append="show = !show"
              validate-on-blur
              outlined
              dense
            ></v-text-field>

            <v-text-field
              v-model.trim="password_confirm"
              :rules="[rules.required, rules.pass]"
              :append-icon="show ? 'fas fa-eye' : 'fas fa-eye-slash'"
              :type="show ? 'text' : 'password'"
              label="Repita su contraseña"
              counter
              @click:append="show = !show"
              validate-on-blur
              outlined
              dense
            ></v-text-field>

            <div class="d-flex">
              <v-checkbox
                v-model="tyc"
                class="py-0"
                label="Acepto los"
                hide-details
                dense
              ></v-checkbox>
              <v-hover v-slot="{ hover }">
                <span
                  style="cursor: pointer; font-size: 16px; margin: 5px 0 0 5px"
                  class="primary--text"
                  :class="hover ? 'text-decoration-underline' : ''"
                  @click="dialog_tyc = true"
                >
                  términos y condiciones
                </span>
              </v-hover>
            </div>

            <v-expand-transition>
            <v-alert
              v-if="error"
              border="left"
              dense
              text
              type="error"
              class="mt-3 mb-n1"
            >{{ error_message }}</v-alert>
            </v-expand-transition>

            <v-row class="mt-2">
              <v-col>
                <v-btn
                  color="success"
                  block
                  type="submit"
                  :loading="load"
                >
                  Aceptar
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  color="error"
                  block
                  :disabled="load"
                  @click="dialog = false"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <TerminosYCondiciones v-model="dialog_tyc" />
  </div>
</template>

<script>
import { db, auth } from '../firebase'
import { mapActions } from 'vuex'
import firebase from 'firebase/app'
import TerminosYCondiciones from '../components/TerminosYCondiciones'

export default {
  data() {
    return {
      show: false,
      load: false,
      error: false,
      dialog_tyc: false,
      tyc: false,
      error_message: '',
      apellido: '',
      nombre: '',
      tipo_documento: '',
      documento: '',
      email: '',
      password: '',
      password_confirm: '',
      tipos_documentos: [],
      rules: {
        required: value => !!value || 'Campo requerido',
        min: value => value.length >= 6 || 'Mínimo 6 caracteres',
        pass: value => value === this.password || 'Las contraseñas no coinciden',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'E-mail inválido'
        },
      },
    }
  },
  async created() {
    await db.collection('tipos_documentos').get()
      .then(res => {
        res.forEach(doc => {
          this.tipos_documentos.push(doc.data().tipo)
        })
        this.tipo_documento = this.tipos_documentos[0]
      })
  },
  props: {
    value: Boolean
  },
  components: {
    TerminosYCondiciones
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
  methods: {
    ...mapActions(['login_signup']),
    async singup() {
      this.error = false
      if (this.$refs.form.validate()) {

        // valida que haya aceptado los tyc
        if (this.tyc) {
          try {

        const usuarios_existentes = []
        this.load = true

        // verifica si existe un usuario registrado con ese documento
        await db.collection('usuarios_registrados').where('documento', '==', this.documento.replaceAll('.', '')).get()
          .then((query) => {
            query.forEach(doc => {
              usuarios_existentes.push(doc.data().email)
            })
          })
          .catch((error) => {
            console.log(error)
          })

        if (usuarios_existentes.length === 0) {

          let bienvenida = { titulo: 'N/A', texto: 'N/A' }
          await db.collection('mensajes_predefinidos').doc('bienvenida').get()
            .then((doc) => {
              if (doc.exists) {
                bienvenida.titulo = doc.data().titulo
                bienvenida.texto = doc.data().texto
              }
            })
            .catch((error) => {
              console.log(error)
            })

          // obtiene la ultima version de los tyc
          let version = await this.$store.dispatch('get_last_version_tyc')

          const usuario = {
            terminos_condiciones: {
              acepto: true,
              version: version
            },
            rol: 'Postulante',
            apellido: this.apellido.toUpperCase(),
            nombre: this.nombre.toUpperCase(),
            foto: '',
            tipo_cuenta: 'EmailAndPassword',
            tipo_documento: this.tipo_documento,
            documento: this.documento.replaceAll('.', ''),
            notificaciones: [{
              leida: false,
              titulo: bienvenida.titulo,
              texto: bienvenida.texto
            }],
            postulaciones: [],
            archivos: [],
            enlaces: [],
            cv: {
              completo: false,
              log: { creacion: new Date(Date.now()).toISOString().substr(0, 10), modificacion: new Date(Date.now()).toISOString().substr(0, 10) },
              cuil: '',
              provincia: '',
              departamento: '',
              localidad: '',
              domicilio: '',
              nacimiento: { fecha: '', lugar: '' },
              telefono: { celular: '', alternativo: ''},
              formacion: [],
              conocimientos: [],
              experiencia: [],
              disp_horaria: '',
              pretension_salarial: '',
              carnet_conducir: false,
            }
          }

          await auth.createUserWithEmailAndPassword(this.email, this.password)
            .then(async (userCredential) => {
              const email = this.email
              this.dialog = false
              // crea el nuevo usuario
              await db.collection('usuarios').doc(userCredential.user.uid).set(usuario)
                .then(async () => {
                  // añade del documento a la coleccion de usuarios_registrados
                  await db.collection('usuarios_registrados').doc(userCredential.user.uid).set({
                    documento: usuario.documento,
                    email: email
                  })
                    .then(async () => {
                      this.login_signup(userCredential.user)
                    })
                    .catch((error) => {
                      this.error = true
                      this.error_message = error.message
                    })
                })
                .catch((error) => {
                  this.error = true
                  this.error_message = error.message
                })

            })
            .catch((error) => {
              this.error = true
              switch (error.code) {
                case 'auth/email-already-in-use':
                  this.error_message = 'Ya existe un usuario registrado con el mismo e-mail'
                  break
              
                default:
                  this.error_message = error.message
                  break
              }
            })

        } else {
          this.error = true
          this.error_message = `Ya existe un usuario registrado con el mismo Nº de documento con el e-mail ${usuarios_existentes.join(' y ')}`
        }

        this.load = false

          } catch (error) {
            this.error = true
            this.error_message = error.message
          }
        } else {
          this.error = true
          this.error_message = 'Acepte los términos y condiciones para continuar'
        }

      }
    },
    async login_google() {
      const provider = new firebase.auth.GoogleAuthProvider()
      auth.languageCode = 'es'
      await auth.signInWithPopup(provider)
        .then((result) => {
          this.dialog = false
          this.login_signup(result.user)
        }).catch((error) => {
          console.log(error)
        })
    },
    clear() {
      this.error = false
      this.show = false
      this.tyc = false
      this.apellido = ''
      this.nombre = ''
      this.documento = ''
      this.email = ''
      this.password = ''
      this.password_confirm = ''
      this.$refs.form.resetValidation()
    }
  }
}
</script>