var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{attrs:{"app":"","color":"secondary","width":"206"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{attrs:{"link":"","to":"/admin/convocatorias"},on:{"click":_vm.limpiar_filtro}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-bullhorn")])],1),_c('v-list-item-title',{staticClass:"ml-n5"},[_vm._v("Búsquedas activas")])],1),_c('v-list-item',{attrs:{"link":"","to":"/admin/postulantes"},on:{"click":_vm.limpiar_filtro}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-users")])],1),_c('v-list-item-title',{staticClass:"ml-n5"},[_vm._v("Postulantes")])],1),_c('v-list-group',{attrs:{"no-action":"","color":"accent"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-icon',{staticStyle:{"margin-left":"3px"},attrs:{"slot":"prependIcon","small":""},slot:"prependIcon"},[_vm._v("fas fa-cog")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"ml-4"},[_vm._v("Configuración")])],1)]},proxy:true}])},[_c('v-icon',{attrs:{"slot":"appendIcon","small":""},slot:"appendIcon"},[_vm._v("fas fa-chevron-down")]),_vm._l((_vm.cruds),function(ref,i){
var title = ref[0];
var icon = ref[1];
var to = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":to},on:{"click":_vm.limpiar_filtro}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}}),_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","left":""},domProps:{"textContent":_vm._s(icon)}})],1)],1)})],2)],1)],1),_c('v-app-bar',{attrs:{"color":"rojobb","flat":"","dark":"","dense":"","app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),(!(_vm.$vuetify.breakpoint.xs || _vm.$route.name === 'Curriculum Vitae'))?_c('v-btn',{staticClass:"mx-1",attrs:{"icon":""},on:{"click":_vm.vovler}},[_c('v-icon',[_vm._v("fas fa-arrow-left")])],1):_vm._e(),_c('v-toolbar-title',{staticClass:"text-h6 text-sm-h5 font-weight-light"},[_vm._v(_vm._s(_vm.currentRouteName))]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":_vm.$vuetify.breakpoint.xs}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":!_vm.$vuetify.breakpoint.xs}},[_vm._v("fas fa-user-circle")]),(!_vm.$vuetify.breakpoint.xs)?_c('div',[_vm._v(_vm._s(_vm.user.nombre))]):_vm._e()],1)]}}])},[_c('v-list',[_c('Password')],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-md-2",attrs:{"icon":""},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-sign-out-alt")])],1)]}}])},[_c('span',[_vm._v("Cerrar sesión")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }