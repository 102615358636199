import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { VueEasyJwt } from "vue-easy-jwt"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/postulaciones',
    name: 'Postulaciones',
    component: () => import(/* webpackChunkName: "postulaciones" */ '../views/Postulaciones.vue'),
    meta: { requireAuth: true, postulanteRol: true }
  },
  {
    path: '/cv/:id?',
    name: 'CV',
    component: () => import(/* webpackChunkName: "cv" */ '../views/CV.vue'),
    meta: { requireAuth: true, postulanteRol: true }
  },
  {
    path: '/admin/convocatorias',
    name: 'Búsquedas activas',
    component: () => import(/* webpackChunkName: "convocatorias" */ '../views/admin/Convocatorias.vue'),
    meta: { requireAuth: true, adminRol: true }
  },
  {
    path: '/admin/convocatoria/:id',
    name: 'Búsqueda',
    component: () => import(/* webpackChunkName: "convocatoria" */ '../views/admin/Convocatoria.vue'),
    meta: { requireAuth: true, adminRol: true }
  },
  {
    path: '/admin/empresas',
    name: 'Empresas',
    component: () => import(/* webpackChunkName: "empresas" */ '../views/admin/Empresas.vue'),
    meta: { requireAuth: true, adminRol: true }
  },
  {
    path: '/admin/areas',
    name: 'Áeras',
    component: () => import(/* webpackChunkName: "areas" */ '../views/admin/Areas.vue'),
    meta: { requireAuth: true, adminRol: true }
  },
  {
    path: '/admin/puestos',
    name: 'Puestos',
    component: () => import(/* webpackChunkName: "puestos" */ '../views/admin/Puestos.vue'),
    meta: { requireAuth: true, adminRol: true }
  },
  {
    path: '/admin/etapas',
    name: 'Etapas',
    component: () => import(/* webpackChunkName: "etapas" */ '../views/admin/Etapas.vue'),
    meta: { requireAuth: true, adminRol: true }
  },
  {
    path: '/admin/mensajes',
    name: 'Mensajes predefinidos',
    component: () => import(/* webpackChunkName: "mensajes" */ '../views/admin/Mensajes.vue'),
    meta: { requireAuth: true, adminRol: true }
  },
  {
    path: '/admin/postulantes',
    name: 'Postulantes registrados',
    component: () => import(/* webpackChunkName: "postulantes" */ '../views/admin/Postulantes.vue'),
    meta: { requireAuth: true, adminRol: true }
  },
  {
    path: '/admin/postulante/:id/:email/:conv?',
    name: 'Curriculum Vitae',
    component: () => import(/* webpackChunkName: "postulante" */ '../views/admin/PostulanteCV.vue'),
    meta: { requireAuth: true, adminRol: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (localStorage.getItem('user')) {
      const jwt = new VueEasyJwt()
      const user = JSON.parse(localStorage.getItem('user'))
      if (!jwt.isExpired(user.token)) {
        // paginas solo para admins
        if (to.matched.some(record => record.meta.adminRol)) {
          if (user.rol === 'Admin') {
            next()
          } else {
            next({ path: '/' })
          }
        }
        // paginas solo para postulantes
        if (to.matched.some(record => record.meta.postulanteRol)) {
          if (user.rol === 'Admin') {
            next({ path: '/admin/convocatorias' })
          } else {
            next()
            await store.dispatch('abrir_modal_tyc', user.id)
          }
        }
      } else {
        next({ path: '/' })
        store.dispatch('logout')
      }
    } else {
      next({ path: '/' })
    }
  } else {
    next()
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.rol == 'Postulante') {
        await store.dispatch('abrir_modal_tyc', user.id) 
      }
    }
  }
})

export default router
