<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="400"
      :persistent="load"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
            class="my-n1"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-title>Cambiar contraseña</v-list-item-title>
          </v-list-item>
      </template>

      <v-card>
        <v-card-title class="text-h5 font-weight-light mb-1">Cambiar contraseña</v-card-title>

        <v-card-text>
          <div v-if="success">
            <v-alert
              border="left"
              dense
              text
              type="success"
              class="mb-5"
            >Su contraseña ha sido cambiada correctamente</v-alert>
            
            <div class="text-center">
              <v-btn
                color="success"
                @click="dialog = false"
              >
                Aceptar
              </v-btn>
            </div>
          </div>

          <v-form v-else ref="form" @submit.prevent="cambiar()">
            <v-text-field
              v-model.trim="password"
              :rules="[rules.required, rules.min]"
              :append-icon="show ? 'fas fa-eye' : 'fas fa-eye-slash'"
              :type="show ? 'text' : 'password'"
              label="Contraseña actual"
              @click:append="show = !show"
              validate-on-blur
            ></v-text-field>

           <v-text-field
              v-model.trim="new_password"
              :rules="[rules.required, rules.min, rules.pass2]"
              :append-icon="show ? 'fas fa-eye' : 'fas fa-eye-slash'"
              :type="show ? 'text' : 'password'"
              label="Contraseña nueva"
              hint="Mínimo 6 caracteres"
              counter
              @click:append="show = !show"
              validate-on-blur
            ></v-text-field>

            <v-text-field
              v-model.trim="password_confirm"
              :rules="[rules.required, rules.pass]"
              :append-icon="show ? 'fas fa-eye' : 'fas fa-eye-slash'"
              :type="show ? 'text' : 'password'"
              label="Repita su contraseña nueva"
              counter
              @click:append="show = !show"
              validate-on-blur
              class="mb-5"
            ></v-text-field>

            <v-alert
              v-if="error"
              border="left"
              dense
              text
              type="error"
              class="mb-5"
            >{{ error_message }}</v-alert>
      
            <v-row>
              <v-col class="col-6">
                <v-btn
                  color="success"
                  block
                  type="submit"
                  :loading="load"
                >
                  Cambiar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  color="error"
                  block
                  :disabled="load"
                  @click="dialog = false"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth } from '../firebase'
import firebase from 'firebase/app'

export default {
  data () {
    return {
      dialog: false,
      show: false,
      load: false,
      success: false,
      error: false,
      error_message: '',
      password: '',
      new_password: '',
      password_confirm: '',
      rules: {
        required: value => !!value || 'Campo requerido',
        min: value => value.length >= 6 || 'Mínimo 6 caracteres',
        pass: value => value === this.new_password || 'Las contraseñas no coinciden',
        pass2: value => value !== this.password || 'La contraseña nueva no puede ser igual a la actual',
      },
    }
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  methods: {
    async cambiar() {
      if (this.$refs.form.validate()) {
        this.error = false
        this.load = true

        const user = auth.currentUser
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email, 
          this.password
        )

        await user.reauthenticateWithCredential(credential)
          .then(async () => {
              await user.updatePassword(this.new_password)
                .then(() => {
                  this.success = true

                }).catch((error) => {
                  this.error = true
                  switch (error.code) {
                    case 'auth/too-many-requests':
                      this.error_message = 'Ha enviado demasiadas solicitudes seguidas. Vuelva a intentarlo más tarde'
                      break

                    default:
                      this.error_message = error.message
                      break
                  }
                })

            }).catch((error) => {
              this.error = true
              switch (error.code) {
                case 'auth/wrong-password':
                  this.error_message = 'Contraseña incorrecta'
                  break

                case 'auth/too-many-requests':
                  this.error_message = 'Ha enviado demasiadas solicitudes seguidas. Vuelva a intentarlo más tarde'
                  break

                default:
                  this.error_message = error.message
                  break
              }
            })

        this.load = false
      }
    },
    clear() {
      this.error = false
      this.show = false
      this.password = ''
      this.new_password = ''
      this.password_confirm = ''
      if (!this.success) this.$refs.form.resetValidation()
    },
  }
}
</script>