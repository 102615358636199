<template>
  <v-app-bar
    color="rojobb"
    flat
    dark
    app
  >
    <v-toolbar-title class="text-h5" :style="$vuetify.breakpoint.xs ? 'cursor: pointer' : 'cursor: default'" @click="ir_a('')">BB Jobs</v-toolbar-title>

    <div v-if="!$vuetify.breakpoint.xs" class="ml-6">
      <v-btn
        v-for="(item, index) in opciones"
        :key="index"
        class="body-1"
        text
        @click="ir_a(item.route)"
      >
        {{ item.title }}
      </v-btn>
    </div>

    <v-spacer></v-spacer>

    <v-menu
      :close-on-content-click="false"
      :nudge-width="300"
      :nudge-left="300"
      max-width="348"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="get_noleidas"
        >
          <v-badge
            v-if="noleidas.length !== 0"
            color="success"
            :content="noleidas.length"
            :dot="$vuetify.breakpoint.xs"
          >
            <v-icon>fas fa-bell</v-icon>
          </v-badge>
          <v-icon v-else>fas fa-bell</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h6 font-weight-light mt-n1">
          Notificaciones
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="refresh"
          ><v-icon small>fas fa-sync</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            v-if="load"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-else>
           <v-alert
              v-if="noleidas.length === 0"
              border="left"
              text
              type="info"
            >
              No hay notificaciones sin leer
            </v-alert>
            <v-list v-else class="pt-0">
              <v-list-item
                v-for="(item, index) in noleidas.slice(0, 2)"
                :key="index"
                class="py-1 px-0"
              >
                <NotificacionesCard :notificacion="item" :user="user" @refresh="get_noleidas" />
              </v-list-item>
            </v-list>
            <div class="text-right mt-1">
              <Notificaciones :notificaciones="user.notificaciones" :user="user" @refresh_popup="get_noleidas" />
            </div>
          </div>

        </v-card-text>
      </v-card>
    </v-menu>

    <v-menu offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :class="$vuetify.breakpoint.xs ? '' : 'ml-2 pl-0 pr-3'"
          text
          v-bind="attrs"
          v-on="on"
          :icon="$vuetify.breakpoint.xs"
        >
          <v-avatar>
            <v-icon v-if="user.foto === ''">fas fa-user-circle</v-icon>
            <img v-else :src="user.foto" class="pa-2">
          </v-avatar>
          <div v-if="!$vuetify.breakpoint.xs">{{ user.nombre }}</div>
        </v-btn>
      </template>

      <v-list v-if="user.tipo_cuenta !== 'Google'">
        <Password />
      </v-list>
    </v-menu>

    <v-menu v-if="$vuetify.breakpoint.xs" offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>fas fa-ellipsis-v</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in opciones"
          :key="index"
          @click="ir_a(item.route)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="logout"
        >
          <v-list-item-title>Cerrar sesión<v-icon right color="error">fas fa-sign-out-alt</v-icon></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-md-2"
          icon
          v-bind="attrs"
          v-on="on"
          @click="logout"
        >
          <v-icon>fas fa-sign-out-alt</v-icon>
        </v-btn>
      </template>
      <span>Cerrar sesión</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex'
import { db } from '../../firebase'
import Password from '../Password'
import NotificacionesCard from '../NotificacionesCard'
import Notificaciones from '../Notificaciones'

export default {
  props: ['user'],
  data() {
    return {
      load: false,
      noleidas: [],
      opciones: [
        { title: 'Búsquedas activas', route: ''},
        { title: 'Postulaciones', route: 'postulaciones'},
        { title: 'Mi CV', route: 'cv'},
      ],
    }
  },
  async created() {
    this.get_noleidas()
  },
  components: {
    Password, NotificacionesCard, Notificaciones
  },
  methods: {  
    ...mapActions(['logout', 'update_user']),
    ir_a(item) {
      this.$router.push(`/${item}`).catch(()=>{})
    },
    get_noleidas () {
      this.noleidas = []
      this.user.notificaciones.forEach(notificacion => {
        notificacion.leida || this.noleidas.push(notificacion)
      })
    },
    async refresh () {
      this.load = true
      await db.collection('usuarios').doc(this.user.id).get()
        .then((doc) => {
          if (doc.exists) {
            this.user.notificaciones = doc.data().notificaciones
            this.update_user(this.user)
            this.get_noleidas()
          }
        })
        .catch((error) => {
          console.log(error)
        })
      this.load = false
    }
  },
}
</script>