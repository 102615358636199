<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      :persistent="load"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="text-right">
          <a
            class="text-decoration-none"
            v-bind="attrs"
            v-on="on"
            @click="get_email"
          >¿Olvidaste tu contraseña?</a>
        </div>
      </template>

      <v-card>
        <v-card-title class="text-h5 font-weight-light mb-1">Recuperar contraseña</v-card-title>

        <v-card-subtitle>Ingrese su e-mail para que podamos enviarle un enlace de recuperación</v-card-subtitle>

        <v-card-text>
          <v-alert
            v-if="success"
            border="left"
            dense
            text
            type="success"
          >E-mail enviado correctamente, por favor revise su bandeja de entrada</v-alert>

          <v-form v-else ref="form">
            <v-text-field
              v-model.trim="email_value"
              :rules="[rules.required, rules.email]"
              label="E-mail"
              validate-on-blur
              outlined
              prepend-inner-icon="fas fa-envelope-open-text"
            ></v-text-field>

            <v-alert
              v-if="error"
              border="left"
              dense
              text
              type="error"
            >{{ error_message }}</v-alert>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end mt-n4 pb-4">
          <v-btn
            v-if="success"
            color="success"
            @click="dialog = false"
          >
            Aceptar
          </v-btn>
          <div v-else>
            <v-btn
              color="success"
              class="mr-4"
              :loading="load"
              @click="recuperacion"
            >
              Enviar <v-icon right>fas fa-paper-plane</v-icon>
            </v-btn>
            <v-btn
              color="error"
              :disabled="load"
              @click="dialog = false"
            >
              Cancelar
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth } from '../firebase'

export default {
  props: ['email'],  
  data () {
    return {
      dialog: false,
      load: false,
      success: false,
      error: false,
      error_message: '',
      email_value: '',
      rules: {
        required: value => !!value || 'Campo requerido',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'E-mail inválido'
        },
      },
    }
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  methods: {
    async recuperacion() {
      if (this.$refs.form.validate()) {

        this.error = false
        this.load = true

        await auth.sendPasswordResetEmail(this.email_value)
          .then(() => {
            this.success = true

          }).catch((error) => {
            this.error = true
            switch (error.code) {
              case 'auth/user-not-found':
                this.error_message = 'No se encontró ningún usuario con el e-mail ingresado'
                break
              
              case 'auth/too-many-requests':
                this.error_message = 'Ha enviado demasiadas solicitudes de recuperación seguidas. Vuelva a intentarlo más tarde'
                break

              default:
                this.error_message = error.message
                break
            }
          })

        this.load = false

      }
    },
    get_email() {
      this.email_value = this.email
    },
    clear() {
      this.error = false
      if (!this.success) this.$refs.form.resetValidation()
    }
  }
}
</script>