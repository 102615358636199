var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"color":"rojobb","flat":"","dark":"","app":""}},[_c('v-toolbar-title',{staticClass:"text-h5",style:(_vm.$vuetify.breakpoint.xs ? 'cursor: pointer' : 'cursor: default'),on:{"click":function($event){return _vm.ir_a('')}}},[_vm._v("BB Jobs")]),(!_vm.$vuetify.breakpoint.xs)?_c('div',{staticClass:"ml-6"},_vm._l((_vm.opciones),function(item,index){return _c('v-btn',{key:index,staticClass:"body-1",attrs:{"text":""},on:{"click":function($event){return _vm.ir_a(item.route)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"nudge-left":300,"max-width":"348","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.get_noleidas}},'v-btn',attrs,false),on),[(_vm.noleidas.length !== 0)?_c('v-badge',{attrs:{"color":"success","content":_vm.noleidas.length,"dot":_vm.$vuetify.breakpoint.xs}},[_c('v-icon',[_vm._v("fas fa-bell")])],1):_c('v-icon',[_vm._v("fas fa-bell")])],1)]}}])},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 font-weight-light mt-n1"},[_vm._v(" Notificaciones "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.refresh}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-sync")])],1)],1),_c('v-card-text',[(_vm.load)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_c('div',[(_vm.noleidas.length === 0)?_c('v-alert',{attrs:{"border":"left","text":"","type":"info"}},[_vm._v(" No hay notificaciones sin leer ")]):_c('v-list',{staticClass:"pt-0"},_vm._l((_vm.noleidas.slice(0, 2)),function(item,index){return _c('v-list-item',{key:index,staticClass:"py-1 px-0"},[_c('NotificacionesCard',{attrs:{"notificacion":item,"user":_vm.user},on:{"refresh":_vm.get_noleidas}})],1)}),1),_c('div',{staticClass:"text-right mt-1"},[_c('Notificaciones',{attrs:{"notificaciones":_vm.user.notificaciones,"user":_vm.user},on:{"refresh_popup":_vm.get_noleidas}})],1)],1)],1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.$vuetify.breakpoint.xs ? '' : 'ml-2 pl-0 pr-3',attrs:{"text":"","icon":_vm.$vuetify.breakpoint.xs}},'v-btn',attrs,false),on),[_c('v-avatar',[(_vm.user.foto === '')?_c('v-icon',[_vm._v("fas fa-user-circle")]):_c('img',{staticClass:"pa-2",attrs:{"src":_vm.user.foto}})],1),(!_vm.$vuetify.breakpoint.xs)?_c('div',[_vm._v(_vm._s(_vm.user.nombre))]):_vm._e()],1)]}}])},[(_vm.user.tipo_cuenta !== 'Google')?_c('v-list',[_c('Password')],1):_vm._e()],1),(_vm.$vuetify.breakpoint.xs)?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-ellipsis-v")])],1)]}}],null,false,34313432)},[_c('v-list',[_vm._l((_vm.opciones),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.ir_a(item.route)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Cerrar sesión"),_c('v-icon',{attrs:{"right":"","color":"error"}},[_vm._v("fas fa-sign-out-alt")])],1)],1)],2)],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-md-2",attrs:{"icon":""},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-sign-out-alt")])],1)]}}])},[_c('span',[_vm._v("Cerrar sesión")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }