export default {
  namespaced: true,
  state: {
    links: [],
  },
  mutations:{
    set_links(state, payload) {
      state.links = payload
    },
    delete_link(state, payload) {
      state.links.splice(payload, 1)
    },
    add_link(state, payload) {
      state.links.push(payload)
    },
  },
  actions:{
    set_links({ commit }, payload) {
      commit('set_links', payload)
    },
    delete_link({ commit }, payload) {
      commit('delete_link', payload)
    },
    add_link({ commit }, payload) {
      commit('add_link', payload)
    },
  },
}