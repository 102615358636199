import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import { auth } from './firebase'
import VueMask from 'v-mask'
import JsonExcel from "vue-json-excel"
import VueEasyJwt from 'vue-easy-jwt'
import VueLoaders from 'vue-loaders'
import 'vue-loaders/dist/vue-loaders.css'

Vue.use(VueLoaders)
Vue.use(VueMask)
Vue.use(VueEasyJwt)
Vue.component("downloadExcel", JsonExcel)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

auth.onAuthStateChanged(async (user) => {
  store.state.loading = true
  if (user) {
    if (store.state.user.rol === 'Admin') {
      store.dispatch('get_empresas')
    }
  }
  store.state.loading = false
})
