export default {
  namespaced: true,
  state: {
    funciones: [],
  },
  mutations:{
    set_funciones(state, payload) {
      state.funciones = payload
    },
    delete_funcion(state, payload) {
      state.funciones.splice(payload, 1)
    },
    add_funcion(state, payload) {
      state.funciones.push(payload)
    },
  },
  actions:{
    set_funciones({ commit }, payload) {
      commit('set_funciones', payload)
    },
    delete_funcion({ commit }, payload) {
      commit('delete_funcion', payload)
    },
    add_funcion({ commit }, payload) {
      commit('add_funcion', payload)
    },
  },
}